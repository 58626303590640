import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import CreditCard from "@material-ui/icons/CreditCard";
import Delete from "@material-ui/icons/Delete";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import DialogTitle from "@material-ui/core/DialogTitle";
import ListItem from "@material-ui/core/ListItem";
import { getDeliveryUpsaleText } from "ecommerce-commons-ui/core/utils";
import CountWidget from "./CountWidget";
import { ExpressCheckout } from "../../ExpressCheckout"
import { calcPrice, renderNameVertical } from "ecommerce-commons-ui/core/utils";
import "./style.styl";

const mapping = {
    pick_up: "Store",
    delivery: "Delivery",
    event: "Event"
};

const getType = (item) => {
    if (!item) return "";
    if (item["ecommerce_store_delivery_option_pickup_id"]) {
        return "pick_up";
    } else if (item["ecommerce_store_delivery_option_rule_id"]) {
        return "delivery";
    } else if (item["ecommerce_store_delivery_option_event_id"]) {
        return "event";
    }
    return "";
};

export default function CartContent(props) {
    const { items, type, deliveryItem, recaptchaKey, location, isMobile, ecommerceStoreId, ecommerceStoreGroupId, isOpen, onClose, onContinue, apiPath, mediaPath, noImage, noWatermark, dispatch } = props;
    const withPayment = ["event"].includes(deliveryItem.delivery_type)
    const [loading, setLoading] = useState(withPayment ? true : false);
    const [paymentAvailable, setPaymentAvailable] = useState(true);
    const total = items.reduce((a, b) => a + (calcPrice(b.product || {}, b.featureList).final_price || 0) * (b.count || 0), 0).toFixed(2);
    const isCartEmpty = !items.length;

    console.log(items, deliveryItem, props)

    return (
        <div className="u-sizeFullHeight u-flexColumn">
            <div className="cart-header u-flexCenter u-justifyContentSpaceBetween u-paddingHorizontal15">
                <div className="u-flexColumn u-paddingVertical15">
                    <DialogTitle className="u-padding0">Cart</DialogTitle>
                    {
                        Object.keys(deliveryItem || {}).length ?
                            <span className="u-fontSize10">{mapping[getType(deliveryItem)] || "Delivery"}: {(deliveryItem || {}).name}</span>
                            :
                            null
                    }
                </div>
                <IconButton className="u-marginRightNegative5" color="inherit" onClick={onClose}>
                    <Close />
                </IconButton>
            </div>
            <List className="cart-content u-paddingVertical10 u-borderTop1 u-borderColorLightest u-minHeight250">
                {
                    isCartEmpty ?
                        <div className="u-sizeFullHeight u-flexColumn u-justifyContentCenter u-alignItemsCenter">
                            <div className="u-paddingHorizontal50 u-paddingBottom20">
                                <img className="u-sizeFullWidth u-hide" src="/static/img/empty_cart_2.png" />
                            </div>
                            <div className="u-textColorNormal u-textAlignCenter u-marginTop50">
                                Empty Cart
                            </div>
                        </div>
                        :
                        null
                }
                {
                    items.map((item, i) => {

                        const product = item.product || {};
                        const featureDict = item.featureDict || {};
                        const featureList = item.featureList || [];
                        const { price_fixed, discount_fixed, discount_percent } = calcPrice(product, featureList);
                        const price = discount_percent ? discount_fixed : price_fixed;
                        const path = product.images ? mediaPath + (product.images || "").split(",")[0] : (noImage || "https://assets.smallcase.com/images/smallcases/200/SCSB_0004.png");
                        const noBorder = (i === items.length - 1) ? " u-border0 " : " u-borderBottom1 u-borderColorLightest ";
                        const cartLimit = parseInt(product.cart_limit || "0") || 0
                        return (
                            <ListItem key={i} className={"u-paddingVertical0 u-paddingRight0 " + noBorder}>
                                <div className="u-flexCenter u-sizeFullWidth u-paddingRight15">
                                    <div className="u-relative u-inlineBlock u-marginVertical4 u-verticalAlignTop u-marginRight15">
                                        <div className={"image-widget-item u-relative u-width80 u-height80"}
                                            style={{ backgroundImage: `url(${path})`, backgroundSize: "contain" }}></div>
                                    </div>
                                    <div className="u-sizeFullWidth u-flexColumn">
                                        <div className="name u-fontSize12 u-textColorNormal u-marginBottom5 u-xs-fontSize12">{renderNameVertical(product, { featureList, features: [] })}</div>
                                        <div className="u-flexCenter u-justifyContentSpaceBetween u-xs-fontSize16">
                                            <div className={discount_percent ? "u-textColorRed" : ""}>$ {price}</div>
                                            {
                                                deliveryItem && deliveryItem.delivery_type === "shipping_event" ?
                                                    <div className="u-flexCenter u-marginRight15">
                                                        <span>Qty: {item.count || 0}</span>
                                                    </div>
                                                    :
                                                    <div className="u-flexCenter">
                                                        <CountWidget itemIdValue={item.id} value={item.count || 0} dispatch={dispatch} limit={cartLimit} />
                                                        <div className="u-flexCenter u-marginLeft5">
                                                            <IconButton className="u-padding2 u-opacity80" onClick={() => dispatch("cart", { id: item.id }, "remove_product")}>
                                                                <Delete className="u-fontSize15" />
                                                            </IconButton>
                                                        </div>
                                                    </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </ListItem>
                        );
                    })
                }
            </List>
            {
                !isCartEmpty ?
                    <div className="cart-actions u-borderTop1 u-borderColorLightest">
                        <div className="u-paddingLeft15 u-flex u-justifyContentSpaceBetween u-xs-fontSize16 u-xs-flexColumn u-paddingRight15 u-paddingVertical15">
                            <div className="u-flexCenter">
                                <div>Total:</div>
                                <div className="u-marginLeft10">$ {total}</div>
                            </div>
                            <span className="u-fontSize10 u-textColorNormal u-xs-marginTop5">
                                {getDeliveryUpsaleText((deliveryItem || {}).delivery_cost_json, parseFloat(total))}
                            </span>
                        </div>
                        {
                            false ?
                                <div className="u-flexCenter u-justifyContentCenter u-paddingTop10 u-paddingBottom50">loading payment details...</div>
                                :
                                (
                                    withPayment && paymentAvailable ?
                                        <div>
                                            <div className="u-flexColumn u-alignItemsCenter">
                                                <div className="u-marginBottom10">Pay Now:</div>

                                                <div className={"u-flexCenter u-xs-flexColumn u-paddingHorizontal15"} style={{ visibilitys1: loading ? "hidden" : "visible" }}>
                                                    <ExpressCheckout onInit={(res) => {
                                                        if (res.ok) {
                                                            setPaymentAvailable(true)
                                                            window.dataLayer && window.dataLayer.push({ event: 'begin_checkout' });
                                                        } else {
                                                            setPaymentAvailable(false)
                                                        }
                                                        console.log("res", res)
                                                        setLoading(false)
                                                    }}
                                                        onSuccess={(order = {}) => {
                                                            window.dataLayer && window.dataLayer.push({
                                                                event: 'placed_order',
                                                                ecommerce: { value: order.total_amount || 10, currency: 'USD' }
                                                            });
                                                        }}
                                                        products={items}
                                                        ecommerceStoreId={ecommerceStoreId}
                                                        rule={deliveryItem}
                                                        dispatch={dispatch}
                                                        deliveryOption={deliveryItem}
                                                        ecommerceStoreGroupId={ecommerceStoreGroupId}
                                                        location={location}
                                                        isMobile={isMobile}
                                                        noLoading
                                                        no
                                                        gift={{ is_gift: false, gift_sender_name: "", gift_recipient_name: "", gift_message: "" }}
                                                        recaptchaKey={recaptchaKey}
                                                        apiPath={apiPath} />

                                                    <div
                                                        style={{
                                                            padding: '5px 20px',
                                                            backgroundColor: '#555555', // Dark gray
                                                            color: '#fff',
                                                            width: "300px",
                                                            borderRadius: '5px',
                                                            textAlign: 'center',
                                                            cursor: 'pointer',
                                                            maxWidth: '292px',
                                                            height: '44px',
                                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                                            transition: 'background-color 0.3s',
                                                        }}
                                                        className="u-flexCenter u-justifyContentCenter u-marginLeft15 u-xs-marginLeft0 u-xs-marginTop15"
                                                        onClick={() => { onClose(); dispatch("/checkout"); }}
                                                        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#333333')}
                                                        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#555555')}
                                                    >
                                                        <CreditCard className="u-marginRight5" fontSize="small"/> Credit Card
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="u-paddingVertical20 u-flexCenter u-justifyContentCenter">or <span className="link u-marginLeft5" onClick={() => { (onContinue ? onContinue() : null); onClose(); }}>Continue Shopping</span></div>
                                        </div>
                                        :
                                        <div className="u-flexCenter u-xs-flexColumn u-justifyContentCenter u-paddingBottom20 u-paddingHorizontal15">
                                            {
                                                deliveryItem && deliveryItem.delivery_type === "shipping_event" ?
                                                    null
                                                    :
                                                    <Button size="small" className="button-grey u-height48 u-marginRight10 u-xs-marginRight0 u-xs-sizeFullWidth"
                                                        onClick={() => { (onContinue ? onContinue() : null); onClose(); }}>
                                                        Continue Shopping
                                                    </Button>
                                            }


                                            <Button className="button-secondary u-height48 u-marginLeft10 u-xs-marginLeft0 u-xs-sizeFullWidth u-xs-marginTop15"
                                                link-meta="/checkout"
                                                onClick={() => { onClose(); dispatch("/checkout"); }}>
                                                Checkout <ChevronRight />
                                            </Button>
                                        </div>
                                )
                        }

                    </div>
                    :
                    null
            }
        </div>
    );
}
